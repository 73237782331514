html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    color: var(--theme-text-color);
    font-size: 2x;
    font-family: "Lato", sans-serif;
    line-height: 1.5;
    background-color: var(--background-color);
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 1rem;
    color: var(--theme-color-dark);
}

h2 {
    margin-top: 2rem;
}

b, strong {
    color: var(--theme-color-dark);
}

img {
    max-width: 100%;
}
