.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 1x 2x;
    color: var(--theme-text-background-color);
    font-size: .8rem;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    background-color: var(--theme-color);
    border-radius: 5x;
    transition: background-color .2s;

    &:hover, &:focus {
        background-color: var(--theme-color-dark);
    }

    &:active {
        background-color: var(--theme-color-darker);
    }

    & :global(.iconify) {
        font-size: 1.2em;
    }
}

.link {
    composes: button;
    text-decoration: none;
}

.submit {
    composes: button;
    font-size: initial;
    border: none;
}

.iconButton {
    composes: link;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    size: 4.5x;
    padding: 0;
    color: var(--theme-color);
    font-size: 1rem;
    background-color: var(--theme-text-background-color);
    transition: opacity .2s;

    &:hover, &:focus, &:active {
        background-color: var(--theme-text-background-color);
    }

    &:hover, &:focus {
        opacity: .6;
    }

    &:active {
        opacity: .4;
    }
}
