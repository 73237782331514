:root {
    --background-color:            #e7eaf5;
    --background-color-dark:       #a7aab8;
    --theme-color-lighter:         #14b9ff;
    --theme-color-light:           #00acf5;
    --theme-color:                 #009cde;
    --theme-color-alpha-2:         rgba(0, 156, 222, .2);
    --theme-color-alpha-4:         rgba(0, 156, 222, .4);
    --theme-color-alpha-6:         rgba(0, 156, 222, .6);
    --theme-color-alpha-8:         rgba(0, 156, 222, .8);
    --theme-color-dark:            #008fcc;
    --theme-color-dark-alpha-2:    rgba(0, 143, 204, .2);
    --theme-color-dark-alpha-4:    rgba(0, 143, 204, .4);
    --theme-color-dark-alpha-6:    rgba(0, 143, 204, .6);
    --theme-color-dark-alpha-8:    rgba(0, 143, 204, .8);
    --theme-color-darker:          #007aad;
    --theme-color-darker-alpha-2:  rgba(0, 122, 173, .2);
    --theme-color-darker-alpha-4:  rgba(0, 122, 173, .4);
    --theme-color-darker-alpha-6:  rgba(0, 122, 173, .6);
    --theme-color-darker-alpha-8:  rgba(0, 122, 173, .8);
    --theme-text-background-color: #fff;
    --theme-text-color:            #111;
    --accent-color:                #c91517;
}
